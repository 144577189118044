@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?tct37s');
  src:
    url('../fonts/icomoon.eot?tct37s#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?tct37s') format('truetype'),
    url('../fonts/icomoon.woff?tct37s') format('woff'),
    url('../fonts/icomoon.svg?tct37s#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cancel-error:before {
  content: '\e900';
}
.icon-check-success:before {
  content: '\e901';
}
.icon-team-management:before {
  content: '\e902';
}
.icon-share:before {
  content: '\e903';
}
.icon-fetch-api:before {
  content: '\e904';
}
.icon-warning:before {
  content: '\e905';
}
.icon-close:before {
  content: '\e906';
}
.icon-pencil:before {
  content: '\e907';
}
.icon-delete:before {
  content: '\e908';
}
.icon-arrow-right:before {
  content: '\e909';
}
.icon-arrow-left:before {
  content: '\e90a';
}
.icon-arrow-double-right:before {
  content: '\e90b';
}
.icon-arrow-double-left:before {
  content: '\e90c';
}
.icon-global-access:before {
  content: '\e90d';
}
.icon-dashboard-sm:before {
  content: '\e90e';
}
.icon-setting:before {
  content: '\e90f';
}
.icon-sidebar-nav-toggle .path1:before {
  content: '\e910';
  color: rgb(11, 105, 142);
}
.icon-sidebar-nav-toggle .path2:before {
  content: '\e911';
  margin-left: -0.9501953125em;
  color: rgb(255, 255, 255);
}
.icon-dashboard-lg:before {
  content: '\e912';
}
.icon-system-settings:before {
  content: '\e913';
}
.icon-features-all:before {
  content: '\e914';
}
.icon-api-gateway:before {
  content: '\e915';
}
.icon-business-and-trade:before {
  content: '\e916';
}
.icon-corporate:before {
  content: '\e917';
}
.icon-arrow:before {
  content: '\e918';
}
