$primary: #0a698d;
$primary-hover: #054056;
$primary-focus: #025573;
$primary-text: #ffffff;

$primary-gradient-1: #05bfdb;
$primary-gradient-2: #088395;
$primary-gradient-3: #0a4d68;

$secondary: #6c757d;
$secondary-hover: #a1cbda;
$secondary-focus: #a7d1df;
$secondary-text: #0a698d;

$primary-dark: #06364b;
$primary-dark-text: #ffffff;

$success: #198754;
$info: #c6effd;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
$white: #ffffff;
$body-bg: #ffffff;
$body-text: #000000;
$body-text-light: #434343;
$body-light-bg: #f9f9f9;
