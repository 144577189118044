// global style sheet
@import '/src/assets/scss/variables.scss';

:root {
  // body colors
  --i-body-bg: #{$body-bg};
  --i-body-text: #{$body-text};
  --i-body-text-60: #{$body-text-light};

  // primary colors
  --i-primary: #{$primary};
  --i-primary-hover: #{$primary-hover};
  --i-primary-focus: #{$primary-focus};
  --i-primary-text: #{$primary-text};

  --i-primary-gradient-1: #{$primary-gradient-1};
  --i-primary-gradient-2: #{$primary-gradient-2};
  --i-primary-gradient-3: #{$primary-gradient-3};

  // secondary colors
  --i-secondary: #{$secondary};
  --i-secondary-hover: #{$secondary-hover};
  --i-secondary-focus: #{$secondary-focus};
  --i-secondary-text: #{$secondary-text};

  // primary dark color
  --i-primary-dark: #{$primary-dark};
  --i-primary-dark-text: #{$primary-dark-text};

  // highlight colors
  --i-danger: #{$danger};
  --i-info: #{$info};
  --i-light: #{$light};
  --i-warning: #{$warning};
  --i-white: #{$white};

  // fonts
  --i-font: 'Open Sans';
}

@font-face {
  font-family: 'Open Sans';
  src: url(/assets/fonts/OpenSans-Regular.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/assets/fonts/OpenSans-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url(/assets/fonts/OpenSans-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

.font__family {
  &-regular {
    font-family: var(--i-font), sans-serif;
    font-weight: 400;
  }
  &-semibold {
    font-family: var(--i-font), sans-serif;
    font-weight: 600;
  }
  &-bold {
    font-family: var(--i-font), sans-serif;
    font-weight: 700;
  }
}

body {
  @extend .font__family-regular;
  background-color: var(--i-body-bg);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .font__family-semibold;
}

.bg {
  &__primary {
    background-color: var(--i-primary) !important;
  }
  &__primary__dark {
    background-color: var(--i-primary-dark) !important;
  }
  &__secondary {
    background-color: var(--i-secondary) !important;
  }
}
.main__card {
  margin: auto;
  max-width: 450px;
  margin-top: 60px;
  .card {
    box-shadow: 1px 1px 18px #b9b9b9;
  }
}

.text__grey-700 {
  color: var(--bs-gray-700);
}
.form-text.text__danger {
  color: var(--bs-form-invalid-color);
}

.form-control {
  &.ng-touched.ng-invalid {
    border-color: var(--bs-form-invalid-color);
  }
}

.dac__link,
.dac__link a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.truncate-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  display: block;
}

.i-icon {
  padding: 0.35rem 0.6rem;
  font-size: 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.45s all ease-in-out;
  &:hover {
    background-color: var(--bs-gray-200);
  }
}
